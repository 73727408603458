import { paths } from 'src/routes/paths';

export const navConfig = [
  { title: 'HOME', path: '/' },
  { title: 'WHAT IS KAMBO?', path: paths.whatIsKambo },
  {
    title: 'KAMBO PRACTITIONER SCHOOL',
    path: paths.kamboPractitionerSchool.root,
    children: [
      {
        order: '1',
        items: [
          { title: 'About Our School', path: paths.kamboPractitionerSchool.about },
          { title: 'Find a School', path: paths.kamboPractitionerSchool.root },
          { title: 'FAQ', path: paths.kamboPractitionerSchool.faq },
        ],
      },
    ],
  },
  {
    title: 'FIND A PRACTITIONER',
    path: paths.practitioners.root,
  },
  {
    title: 'MORE',
    path: '',
    children: [
      {
        order: '1',
        items: [{ title: 'Tribal Detox Store', path: paths.eCommerce.products }],
      },
      {
        order: '2',
        items: [{ title: 'Media Gallery', path: paths.gallery }],
      },
      {
        order: '3',
        items: [{ title: 'Reviews', path: paths.reviews }],
      },
    ],
  },
];
