import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import { SplashScreen } from 'src/components/loading-screen';
import MainLayout from 'src/layouts/main';

import AuthGuard from '../route-guards/AuthGuard';

import { eCommerceRoutes } from './ecommerce';
import { errorRoutes } from './error';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/home'));
const WhatIsKamboPage = lazy(() => import('src/pages/what-is-kambo'));
const PractitionerSchools = lazy(() => import('src/pages/all-schools'));
const AboutOurSchool = lazy(() => import('src/pages/about-our-school'));
const SchoolsFAQ = lazy(() => import('src/pages/school-faq'));
const Practitioners = lazy(() => import('src/pages/practitioners'));
const ImageGallery = lazy(() => import('src/pages/gallery'));
const PrivacyPolicy = lazy(() => import('src/pages/privacy-policy'));
const TermsOfService = lazy(() => import('src/pages/terms-of-service'));
const Reviews = lazy(() => import('src/pages/reviews'));
const Docs = lazy(() => import('src/pages/documentation'));

export default function Router() {
  return useRoutes([
    {
      element: (
        <AuthGuard>
          <MainLayout>
            <Suspense fallback={<SplashScreen />}>
              <Outlet />
            </Suspense>
          </MainLayout>
        </AuthGuard>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'what-is-kambo', element: <WhatIsKamboPage /> },
        { path: 'schools', element: <PractitionerSchools /> },
        { path: 'about-our-school', element: <AboutOurSchool /> },
        { path: 'schools-faq', element: <SchoolsFAQ /> },
        { path: 'practitioners', element: <Practitioners /> },
        { path: 'gallery', element: <ImageGallery /> },
        { path: 'reviews', element: <Reviews /> },
        { path: 'docs/$jf12345$', element: <Docs /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'terms-of-service', element: <TermsOfService /> },

        ...eCommerceRoutes,
      ],
    },

    ...errorRoutes,

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
