import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SocialIcon } from 'react-social-icons';

import Logo from 'src/components/logo';

export default function Footer() {
  const theme = useTheme();

  const simpleFooter = (
    <Container sx={{ py: 8, textAlign: 'center' }}>
      <Logo />

      <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }} mt={2}>
        © 2019. Tribal Detox. All rights reserved.
      </Typography>
    </Container>
  );

  const mainFooter = (
    <>
      <Container
        sx={{
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stack
          py={2}
          spacing={{ xs: 3, md: 3 }}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Stack alignItems="flex-start" spacing={1}>
            <Logo />
          </Stack>
          <Stack direction={'row'} alignItems="flex-start" spacing={2}>
            <SocialIcon
              style={{ width: 30, height: 30 }}
              url="https://www.facebook.com/tribaldetox"
              target="_blank"
            />
            <SocialIcon
              style={{ width: 30, height: 30 }}
              url="https://www.instagram.com/tribaldetox"
              target="_blank"
            />
          </Stack>
        </Stack>
      </Container>

      <Divider />

      <Container>
        <Stack spacing={1} sx={{ py: 2, textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            © 2019. Tribal Detox. All rights reserved.
          </Typography>

          <Stack direction="row" spacing={3} justifyContent="center">
            <Link
              href="privacy-policy"
              target="_blank"
              variant="caption"
              sx={{ color: 'text.secondary', cursor: 'pointer' }}
            >
              Privacy Policy
            </Link>

            <Link
              href="terms-of-service"
              target="_blank"
              variant="caption"
              sx={{ color: 'text.secondary', cursor: 'pointer' }}
            >
              Terms of Service
            </Link>
          </Stack>
        </Stack>
      </Container>
    </>
  );

  return (
    <footer
      style={{
        backgroundColor: theme.palette.grey['800'],
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {mainFooter}
    </footer>
  );
}
