import 'src/global.css';

// ----------------------------------------------------------------------

import { QueryClient, QueryClientProvider } from 'react-query';
import MotionLazy from 'src/components/animate/motion-lazy';
import ProgressBar from 'src/components/progress-bar';
import { SettingsProvider } from 'src/components/settings';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { LocalizationProvider } from 'src/locales';
import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';

// ----------------------------------------------------------------------
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

export default function App() {
  useScrollToTop();

  return (
    <LocalizationProvider>
      <QueryClientProvider client={queryClient}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          }}
        >
          {/* <AuthProvider> */}
          <ThemeProvider>
            <MotionLazy>
              <ProgressBar />
              {/* <SettingsDrawer /> */}
              <Router />
            </MotionLazy>
          </ThemeProvider>
          {/* </AuthProvider> */}
        </SettingsProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}
