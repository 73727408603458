import { lazy } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import EcommerceLayout from 'src/layouts/ecommerce';

// ----------------------------------------------------------------------

const CartPage = lazy(() => import('src/pages/e-commerce/cart'));
const ProductPage = lazy(() => import('src/pages/e-commerce/product'));
const ProductsPage = lazy(() => import('src/pages/e-commerce/products'));
const OrderCompletedPage = lazy(() => import('src/pages/e-commerce/order-completed'));

// ----------------------------------------------------------------------

export const eCommerceRoutes = [
  {
    path: 'e-commerce',
    element: (
      <EcommerceLayout>
        <Outlet />
      </EcommerceLayout>
    ),
    children: [
      { path: '', element: <Navigate to="products" replace /> },
      { path: 'products', element: <ProductsPage /> },
      { path: 'product/:id', element: <ProductPage /> },
      { path: 'cart', element: <CartPage /> },
      { path: 'order-completed', element: <OrderCompletedPage /> },
    ],
  },
];
